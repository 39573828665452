export const getUserInfo = async () => {
  const token = localStorage.getItem('access_token');
  if (!token) {
    return null;
  }
  try {
    const req = await fetch(
      `${process.env.GATSBY_TCAL_API_URL}/users/user-info`,
      {
        headers: {
          'auth-provider': 'auth0',
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (!req.ok) {
      return null;
    }

    const res = await req.json();

    return res.user;
  } catch (err) {
    throw new Error('There was a problem getting the user');
  }
};
